/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, Plyr) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $loader = $body.find('#page-loader'),
            $plyrPlayer = $('.plyr-html-video-player')
            ;

        $body.on('loaderEnd', () => console.log('ended'))


        /*
        |
        | Mobile Detect 
        | (for iPhone Only)
        |------------------
        */
        function isIphone() {
            return !!navigator.userAgent.match(/iPhone/i);
        }

        function iPhoneModel() {
            var iHeight = window.screen.height;
            var iWidth = window.screen.width;

            if (iWidth === 414 && iHeight === 896) {
                return "Xmax-Xr";
            }
            else if (iWidth === 375 && iHeight === 812) {
                return "X-Xs";
            }
            else if (iWidth === 320 && iHeight === 480) {
                return "4";
            }
            else if (iWidth === 375 && iHeight === 667) {
                return "6";
            }
            else if (iWidth === 414 && iHeight === 736) {
                return "6+";
            }
            else if (iWidth === 320 && iHeight === 568) {
                return "5";
            }
            else if (iHeight <= 480) {
                return "2-3";
            }
            return 'none';
        }

        function iOSversion() {
            let d, v;
            // if (/iP(hone|od|ad)/.test(navigator.platform)) {
            if (!!navigator.userAgent.match(/iPhone/i)) {
                //   v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                v = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
                d = {
                    status: true,
                    version: parseInt(v[1], 10),
                    info: parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10)
                };
            } else {
                d = { status: false, version: false, info: '' }
            }
            return d;
        }

        // function iOSversion() {
        //     let d, v;
        //     // if (/iP(hone|od|ad)/.test(navigator.platform)) {
        //     if ( !!navigator.userAgent.match(/iPhone/i) ) {
        //     //   v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        //       v = (navigator.userAgent).match(/Version\/(\d+).(\d+)?/);
        //       d = {
        //         status: true,
        //         version: parseInt(v[1], 10) , 
        //         info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
        //       };
        //     }else{
        //       d = {status:false, version: false, info:''}
        //     }
        //     return d;
        // }

        if (isIphone() && iPhoneModel() === "6" && iOSversion().version < 12) {

            $loader.remove()

        }

        /*
        |
        | LazyLoad Images
        |-------------------------
        */
        let lazyImages = $('img.lazy-image');

        const lazyLoadImages = (windowHeight, scrollPosition, threshold) => {

            $.each(lazyImages, (index, element) => {

                if ($(element).offset().top < windowHeight) {

                    let src = $(element).data('src');
                    $(element).attr('src', src);
                    $(element).removeAttr('data-src');

                } else {

                    // let delta = $( element ).offset().top;
                    // let delta = $( element ).offset().top - windowHeight;
                    let delta = $(element).offset().top - (windowHeight + threshold);

                    if (scrollPosition > delta) {

                        let src = $(element).data('src');
                        $(element).attr('src', src);
                        $(element).removeAttr('data-src');
                    }

                }

            });

        }

        /*
        |
        | HTML5 Video Player (Plyr)
        |-------------------------
        */

        $.each($plyrPlayer, (index, element) => {

            const $videoId = $(element).attr('id', `plyr-player-${index + 1}`).attr('id');

            let player = new Plyr(`#${$videoId}`, {
                controls: []
            });
            $(window).on('load', () => {
                player.play()
            })
        })

        $('#sound-yt').click(function () {
            if ($(".plyr-html-video-player").prop('muted')) {
                $(".plyr-html-video-player").prop('muted', false);
                $('.mute').show();
                $('.unmute').hide();
            } else {
                $(".plyr-html-video-player").prop('muted', true);
                $('.mute').hide();
                $('.unmute').show();
            }
        });



        // $body.load( () => {

        //     console.log('loading...');
        // })

        // $( document ).ready(function() {
        //     console.log( "ready!" );
        // });


        /*
        |
        | Header menu scroll
        |-----------------
        */
        let oldScrollY = 0

        const updateMenu = (oldScrollY) => {
            const currentScrollY = window.scrollY;
            const oldScrollYOffset = $('.header-container').outerHeight();
            if (currentScrollY < 100) {
                // $('#header').removeClass('translated');
                $('#header').removeClass('active');
            } else if (currentScrollY > oldScrollY) {
                // $('#header').addClass('translated');
                $('#header').addClass('active');
            } else {
                // $('#header').removeClass('translated');
                $('#header').addClass('active');
            }

            if (!currentScrollY) {
                $('.nav-drop').removeClass('top');
            }

            return currentScrollY;
        }

        oldScrollY = updateMenu(oldScrollY);

        window.addEventListener('scroll', function (e) {
            oldScrollY = updateMenu(oldScrollY);
        })

        /*
        |
        | scrollto
        |-----------------
        */

        $('.js-scroll-to').on('click', function () {
            const page = $(this).attr('data-anchor');
            const speed = 750;
            $('html, body').animate({ scrollTop: $(page).offset().top }, speed);
            return false;
        });

        /*
        |
        | Mobile header image
        |-----------------
        */

        const changeBackgroundMobile = () => {
            const el = $('.js-header-mobile')
            const data = el.attr('data-image-mobile')
            console.log(data)
            el.css('background-image', `url('${data}')`)
        }

        const mobile_breakpoint = 768;

        if ($(window).width() < mobile_breakpoint) {
            changeBackgroundMobile()
        }

        $(window).on('resize', () => {
            if ($(window).width() < mobile_breakpoint) {
                changeBackgroundMobile()
            }
        })

        $('.js-play').on('click', (e) => {
            $('.js-play').hide()
            $('.js-iframe').trigger('click')
        })


        /*
        |
        | FAQ
        |-----------------
        */

        $('.js-titleContainer').on('click', (e) => {
            const id = $(e.currentTarget).attr('data-index');

            $('.js-faq-element').css('opacity', .5)
            $(e.currentTarget).parent().css('opacity', 1)

            if (!$(e.currentTarget).hasClass('active')) {

                $(`.js-textContainer`).removeClass('active');
                $(`.js-textContainer[data-index=${id}]`).addClass('active');

                $(`.js-titleContainer`).removeClass('active');
                $(`.js-titleContainer[data-index=${id}]`).addClass('active');

            } else {
                $(`.js-titleContainer`).removeClass('active');
                $(`.js-textContainer`).removeClass('active');
            }
        })

        /*
        |
        | Contact
        |-----------------
        */
        $('.js-show-contact').on('click', (e) => {
            $('.js-contact').addClass('active');
        })
        /*
        |
        | Swiper
        |-----------------
        */

        const swiperRecipe = new Swiper('.js-swiper-recipes', {
            lazy: true,
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                el: '.js-swiper-pagination',
                dynamicBullets: true,
            },
            navigation: {
                nextEl: '.js-swiper-button-next',
                prevEl: '.js-swiper-button-prev',
            },
        })


        /*
        |
        | Print
        |-----------------
        */

        $('.js-print').on('click', function () {
            window.print();
        })

        let windowHeight = $(window).height(),
            scrollPosition = 0,
            threshold = 200
            ;

        lazyLoadImages(windowHeight, scrollPosition, threshold);

        $(window).on('scroll', () => {

            scrollPosition = $(window).scrollTop();

            lazyLoadImages(windowHeight, scrollPosition, threshold);

        });

    }
}
