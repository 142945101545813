/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        //EVENTS

        let wistiaEmbeds = document.querySelectorAll( ".wEmbed" );
	
		for (let i = 0; i < wistiaEmbeds.length; i++) {
			wistiaEmbeds[i].addEventListener( "click", function() {

                $(`.js-image[data-id="${this.dataset.wembed}"]`).hide()
                $(`.js-cover[data-id="${this.dataset.wembed}"]`).hide()
                $(`.js-player[data-id="${this.dataset.wembed}"]`).hide()

				let iframe = document.createElement("iframe");
				let videoid = this.dataset.wembed;
			
				iframe.setAttribute("frameborder", "0");
				iframe.setAttribute("id", videoid);
				iframe.setAttribute("allowfullscreen", "");
                iframe.setAttribute("data-ot-ignore");
				iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.wembed + "?autoplay=1&rel=0");
				iframe.setAttribute("autoplay", "allow");
			
				this.innerHTML = "";
				this.appendChild(iframe);
			} );
		};
    }
}
