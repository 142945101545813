/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        //EVENTS
        $('.js-filter-element').on('click', (e) => {
            const $el = $(e.currentTarget)

            const index = $el.attr('data-index')
            $('.js-intro').removeClass('show')
            $(`.js-intro[data-index="${index}"]`).addClass('show')

            $('.js-filter-element').removeClass('active')
            $el.toggleClass('active') 
            
            $('.js-filter-element-all').removeClass('active')

            $('.js-card-product').removeClass('protected')

            if($('.js-filter-element.active').length < 1) {
                $('.js-card-product').removeClass('hidden')
                $('.js-card-product').parent().css('display', 'block')
                return
            } else {
                for(let i = 0; i < $('.js-filter-element.active').length; i++) {
                    const slug = $($('.js-filter-element.active')[i]).attr('data-slug')

                    $('.js-card-product').each((index, el) => {
                        const arr = $(el).attr('data-cat').split('/')
         
                        for(let ii = 0; ii < arr.length; ii++) {
                            if( arr[ii] !== slug) {
                                $(el).not('.protected').addClass('hidden')
                                $(el).not('.protected').parent().css('display', 'none')
                            } else {
                                $(el).removeClass('hidden').addClass('protected')
                                $(el).parent().css('display', 'block')
                                break;
                            }
                        }
                        
                    })
                }
            }          
            
        })

        $('.js-filter-element-all').on('click', (e) => {
            const $el = $(e.currentTarget)
            $el.toggleClass('active')

            const index = $el.attr('data-index')
            $('.js-intro').removeClass('show')
            $(`.js-intro[data-index="${index}"]`).addClass('show')

            $('.js-filter-element').removeClass('active')

            $('.js-card-product').each((index, el) => {
                $(el).removeClass('hidden')
                $('.js-card-product').parent().css('display', 'block')
            })
        })
    }
}
