/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        //EVENTS
        $('.js-filter-select').on('change', (e) => {
            const val = $(e.currentTarget).val()

            $('.js-card-recette').each((index, el) => {
                const cat = $(el).attr('data-cat')

                if(val && cat !== val) {
                    $(el).addClass('hidden')
                    $(el).parent().css('display', 'none')
                } else {
                    $(el).removeClass('hidden')
                    $(el).parent().css('display', 'block')
                }

            })
        })
    }
}
